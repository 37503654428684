import "./style.css"
import * as THREE from "three"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js"
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js"
import * as dat from "lil-gui"
import heroVertex from "./shaders/heroPlaneShader/vertex.glsl"
import heroFragment from "./shaders/heroPlaneShader/fragment.glsl"
import * as TWEEN from "@tweenjs/tween.js"
import isEmail from "validator/es/lib/isEmail"
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

window.three = THREE

let debug = false
let guiControls = false
if (window.location.hash === "#debug") {
    debug = false
    guiControls = true
}
const debugParameters = {}
debugParameters.TRANS_MAX = 0.95

//Global states
let transition = debugParameters.TRANS_MAX
let stopTransition = false

/**
 * GLTF loaders
 */
const dracoloader = new DRACOLoader()
dracoloader.setDecoderPath("/draco/")
const gltfLoader = new GLTFLoader()
gltfLoader.setDRACOLoader(dracoloader)
const textureLoader = new THREE.TextureLoader()

/**
 * Base
 */
// Debug
let gui = null
if (guiControls) {
    gui = new dat.GUI()
}

// Canvas
const canvas = document.querySelector("canvas.webgl")
const canvas2 = document.querySelector("canvas.canvas2")

// Scene
const scene = new THREE.Scene()

/**
 * Loading models
 */
const heroRobotEyes = textureLoader.load("/textures/eyes.png")
heroRobotEyes.generateMipmaps = false
heroRobotEyes.minFilter = THREE.NearestFilter
heroRobotEyes.center.set(0.5, 0.5)
let robot = null
let robotColors = []
let robotBW = []
window.robotColors = robotColors
window.robotBW = robotBW
let robotEyeMaterial = null
gltfLoader.load("/models/heroRobot/Hero_robot.glb", (model) => {
    scene.add(model.scene)
    window.robot = model.scene
    model.castShadow = true
    for (let i = 0; i < model.scene.children.length; i++) {
        const child = model.scene.children[i]
        child.castShadow = true
        if (child.name === "displayImg") {
            child.material.transperent = true
            child.material.map = heroRobotEyes
            child.material.emissive.set("#000000")
            child.material.emissiveMap = heroRobotEyes
            robotEyeMaterial = child.material
            // child.material.onBeforeCompile = (shader) => {
            //     console.log(shader)
            //     shader.fragmentShader = shader.fragmentShader.replace(
            //         "#include <map_fragment>",
            //         `#ifdef USE_MAP

            //     vec4 sampledDiffuseColor = vec4(vUv, 1.0, 1.0 );

            //     #ifdef DECODE_VIDEO_TEXTURE

            //         sampledDiffuseColor = vec4( mix( pow( sampledDiffuseColor.rgb * 0.9478672986 + vec3( 0.0521327014 ), vec3( 2.4 ) ), sampledDiffuseColor.rgb * 0.0773993808, vec3( lessThanEqual( sampledDiffuseColor.rgb, vec3( 0.04045 ) ) ) ), sampledDiffuseColor.w );

            //     #endif

            //     diffuseColor *= sampledDiffuseColor;

            //     #endif`
            //     )
            // }
        }
        // console.log(child.name)
        // console.log(child.material.color)
        robotColors[i] = new THREE.Color()
        robotColors[i].lerp(child.material.color, 1)
        let avgColor =
            (child.material.color.r + child.material.color.g + child.material.color.b) / 3
        robotBW[i] = new THREE.Color()
        robotBW[i].r = robotBW[i].g = robotBW[i].b = avgColor
        child.material.color.lerp(robotBW[i], 1)
    }
    if(window.screen.width < 490) {
        model.scene.position.set(-3, 0, 4)
        model.scene.lookAt(-3, 0, 10)
    } else if (window.screen.width <750) {
        model.scene.position.set(0, 0, 4)
        model.scene.lookAt(0, 0, 10)
    }else if (window.screen.width <960) {
        model.scene.position.set(2, 0, 4)
        model.scene.lookAt(2, 0, 10)
    } else {
        model.scene.position.set(4, 0, 4)
        model.scene.lookAt(4, 0, 10)
    }
    model.scene.rotation.y = Math.PI
    robot = model
    heroSpotLight.lookAt(model.scene.position)
    if (gui) {
        gui.add(model.scene.rotation, "y")
            .min(-Math.PI)
            .max(Math.PI)
            .step(0.01)
            .name("Robot Rotation")
    }
})

/**
 * Hero plane
 */
const planeUniform = {
    utransition: { value: 0.0 },
    uColor: {
        value: new THREE.Vector3(0, 0, 0),
    },
}

const heroPlaneMaterial = new THREE.MeshPhysicalMaterial({
    // vertexShader: heroVertex,
    // fragmentShader: heroFragment,
    metalness: 0,
    roughness: 1,
    transparent: true,
    color: "#d3d3d3",
})
heroPlaneMaterial.onBeforeCompile = (shader) => {
    shader.uniforms.utransition = planeUniform.utransition
    shader.uniforms.uColor = planeUniform.uColor
    shader.vertexShader = shader.vertexShader.replace(
        "#include <common>",
        `
        #define USE_UV
    #include <common>
    ${heroVertex}
    `
    )
    shader.vertexShader = shader.vertexShader.replace(
        "#include <uv_vertex>",
        `
        #include <uv_vertex>
        vtransition = utransition;
        vColor = uColor;
    `
    )
    shader.fragmentShader = shader.fragmentShader.replace(
        "#include <common>",
        `
        #include <common>
        #define USE_UV
        varying float vtransition;
        varying vec3 vColor;
    `
    )
    shader.fragmentShader = shader.fragmentShader.replace("#include <color_fragment>", heroFragment)
    // shader.fragmentShader = shader.fragmentShader.replace(
    //     "#include <output_fragment>",
    //     `
    //     #include <output_fragment>
    //     gl_FragColor.rgb += 0.5;
    // `
    // )
}
const heroPlane = new THREE.Mesh(new THREE.PlaneGeometry(300, 300), heroPlaneMaterial)
heroPlane.position.z = -5
heroPlane.position.x = -25
heroPlane.receiveShadow = true

scene.add(heroPlane)

/***
 * Switching Action
 */
const heroText1 = document.getElementsByClassName("hero-text")[0]
const heroText2 = document.getElementsByClassName("hero-text")[1]
const tagLine = document.getElementsByClassName("tag-line")[0]
let transitionAniEx = 0

const heroSwitchFunction = () => {
    if (robot && !debug && !stopTransition) {
        if (transition === debugParameters.TRANS_MAX) {
            transition = 0.0
            const tween = new TWEEN.Tween({
                camX: camera.position.x,
                camY: camera.position.y,
                camZ: camera.position.z,
                ambInten: 0,
                time: 1,
                heroSpotLightPosX: heroSpotLight.position.x,
                transitionAni: transitionAniEx,
                robotRotationY: robot.scene.rotation.y,
            })
                .to({
                    camX: 9,
                    camY: 2,
                    camZ: 10,
                    ambInten: 0.1,
                    time: 0,
                    heroSpotLightPosX: 42,
                    transitionAni: debugParameters.TRANS_MAX,
                    robotRotationY: 3.84,
                })
                .onUpdate((params) => {
                    camera.position.set(params.camX, params.camY, params.camZ)
                    let i = 0
                    transitionAniEx = params.transitionAni
                    for (const child of robot.scene.children) {
                        child.material.color.lerp(robotColors[i], 1 - params.time)
                        if (child.name === "headDisplayBorder") {
                            //console.log(1 - params.time)
                            console.log(child.material.color)
                        }
                        i++
                    }
                    heroSpotLight.position.x = params.heroSpotLightPosX
                    robot.scene.rotation.y = params.robotRotationY
                    planeUniform.utransition.value = params.transitionAni
                    light.intensity = params.ambInten
                    heroText2.style.color = `hsl(100, 0%, ${params.time * 100}%)`
                    tagLine.style.color = `hsl(100, 0%, ${params.time * 100}%)`
                    const t = (1 - params.time) * 0.5
                    const eyecolor = new THREE.Color(t, t, t)
                    robotEyeMaterial.emissive.copy(eyecolor)
                })
                .easing(TWEEN.Easing.Exponential.In)
                .onStart(() => {
                    console.log("Animation stoped")
                    heroSpotLightAnimation.stop()
                })
            tween.start()
        } else {
            transition = debugParameters.TRANS_MAX
            const tween = new TWEEN.Tween({
                camX: camera.position.x,
                camY: camera.position.y,
                camZ: camera.position.z,
                ambInten: 0.1,
                time: 0,
                transitionAni: transitionAniEx,
                robotRotationY: robot.scene.rotation.y,
            })
                .to({
                    camX: 13.75,
                    camY: 2.14,
                    camZ: 15.22,
                    ambInten: 0,
                    time: 1,
                    transitionAni: 0.0,
                    robotRotationY: Math.PI,
                })
                .onUpdate((params) => {
                    camera.position.set(params.camX, params.camY, params.camZ)
                    let i = 0
                    transitionAniEx = params.transitionAni
                    for (const child of robot.scene.children) {
                        child.material.color.lerp(robotBW[i], params.time)
                        if (child.name === "headDisplayBorder") console.log(child.material.color)
                        i++
                    }
                    robot.scene.rotation.y = params.robotRotationY
                    planeUniform.utransition.value = params.transitionAni
                    light.intensity = params.ambInten
                    heroText2.style.color = `hsl(100, 0%, ${params.time * 100}%)`
                    tagLine.style.color = `hsl(100, 0%, ${params.time * 100}%)`
                    const t = (1 - params.time) * 0.5
                    const eyecolor = new THREE.Color(t, t, t)
                    robotEyeMaterial.emissive.copy(eyecolor)
                })
                .easing(TWEEN.Easing.Exponential.In)
                .onComplete(() => {
                    console.log("Animation started")
                    heroSpotLightAnimation.start()
                })
            tween.start()
        }
    }
}

document.querySelector(".heroWrapper").addEventListener("mouseup", heroSwitchFunction)
window.addEventListener("load", () => {
    setTimeout(heroSwitchFunction, 3000)
})
/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight,
}

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 200)
camera.position.set(13.75, 2.14, 15.22)
camera.rotation.set(-0.5184, 1.26, 0.5)
scene.add(camera)

//Controls
let controls = null
if (debug) {
    controls = new OrbitControls(camera, canvas)
    controls.enableDamping = true
}
/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.outputEncoding = THREE.sRGBEncoding
renderer.setClearAlpha(0)
renderer.shadowMap.enabled = true

if (gui) {
    gui.add(camera.position, "x").min(-50).max(20).step(0.1)
    gui.add(camera.position, "y").min(-50).max(20).step(0.1)
    gui.add(camera.position, "z").min(-50).max(20).step(0.1)
    gui.add(camera.rotation, "x").min(-Math.PI).max(Math.PI).step(0.01)
    gui.add(camera.rotation, "y").min(-Math.PI).max(Math.PI).step(0.01)
    gui.add(camera.rotation, "z").min(-Math.PI).max(Math.PI).step(0.01)
    debugParameters.logCamera = () => {
        console.log(camera.position)
        console.log(camera.rotation)
        console.log(camera.quaternion)
    }
    gui.add(debugParameters, "logCamera").name("logCamera")
}

//Lights
const light = new THREE.AmbientLight("#d3d3d3", 0)
scene.add(light)
//const heroDirectionalLight = new THREE.DirectionalLight("#ffffff", 0.1)
//scene.add(heroDirectionalLight)
const heroSpotLight = new THREE.SpotLight("#ffffff", 2, 100, Math.PI / 10)
heroSpotLight.position.set(120, 24, -7)
heroSpotLight.castShadow = true
heroSpotLight.shadow.mapSize.width = 1024 / 2
heroSpotLight.shadow.mapSize.height = 1024 / 2
heroSpotLight.shadow.camera.near = 1
heroSpotLight.shadow.camera.far = 50
heroSpotLight.shadow.camera.fov = 30
if (gui) {
    gui.add(light, "intensity").min(0).max(2).step(0.01).name("Ambient Light")
    gui.add(heroDirectionalLight.position, "x").min(-50).max(50).step(0.1)
    gui.add(heroDirectionalLight.position, "y").min(-50).max(50).step(0.1)
    gui.add(heroDirectionalLight.position, "z").min(-50).max(50).step(0.1)
    gui.add(heroDirectionalLight, "intensity").min(0).max(2).step(0.01).name("SpotLight")
    //gui.add(heroDirectionalLight, "intensity").min(0).max(1).step(0.01).name("dirLight")
}
scene.add(heroSpotLight)
/**
 * Hero Spot Light Animation
 */
let aniCount = 0
const heroSpotLightAnimation = new TWEEN.Tween({
    x: heroSpotLight.position.x,
    inten: heroSpotLight.intensity,
})
    .to({
        x: -80,
    })
    .easing(TWEEN.Easing.Linear.None)
    .duration(4000)
    .onUpdate((params) => {
        heroSpotLight.position.x = params.x
        //console.log(heroSpotLight.position.x)
    })
    .repeat(Infinity)

heroSpotLightAnimation.start()
window.spotLight = heroSpotLight

//---------------------------------------------------------------------------------------

if (debug) {
    const spotLightHelper = new THREE.SpotLightHelper(heroSpotLight)
    scene.add(spotLightHelper)
}

heroPlane.lookAt(camera.position)

/**
 * Custome Controls
 */
// const cameraControlsPrev = {
//     x: 0,
//     y: 0,
// }

/***
 *
 *  Intersection observers
 *
 */
const faders = document.querySelectorAll(".fade-in")
const control = document.getElementById("control-fade")
const we_provide_title = document.getElementById("we-provide-title")
console.log(faders)
const appearOnScrollOptions = {
    threshold: [0, 0.5],
    rootMargin: "-50% 0px -48% 0px",
}
const appearOnScroll = new IntersectionObserver((entries, appearOnScroll) => {
    entries.forEach((entry) => {
        if (!entry.isIntersecting) {
            entry.target.classList.remove("appear")
        } else {
            entry.target.classList.add("appear")
        }
    })
    if(control.classList.contains("appear")) {
        we_provide_title.classList.add("appear")
        console.log("added")
    }
    else {
        we_provide_title.classList.remove("appear")
        console.log("removed")
    }
}, appearOnScrollOptions)

faders.forEach((fader) => {
    appearOnScroll.observe(fader)
})

window.addEventListener("scroll", () => {
    // if (scrollY > innerHeight) {
    //     console.log("stop")
    //     droneCanvasContainer.style.top = scrollY
    // } else {
    //     droneCanvasContainer.style.top = "150vh"
    // }
    if (window.scrollY > 20 && !stopTransition) {
        if (transition == debugParameters.TRANS_MAX) heroSwitchFunction()
        stopTransition = true
    } else if (window.scrollY < 20 && stopTransition) {
        stopTransition = false
        if (transition == 0) heroSwitchFunction()
    }
})

document.getElementById("form-submit").addEventListener("click", (e) => {
    e.preventDefault()
    const name = document.getElementById("form-name").value
    const email = document.getElementById("form-email").value
    const message = document.getElementById("form-message").value
    console.log(email, name)
    if (name.length <= 0 || !isEmail(email)) {
        const notification = document.getElementById("email-response")
        notification.innerHTML = "Enter valid data"
        notification.classList.add("appear")
        setTimeout(() => {
            notification.classList.remove("appear")
        }, 2000)
        setTimeout(() => {
            notification.innerHTML = "Thanks for your response"
        }, 2100)
        return
    }

    fetch("https://backend.ackrobotics.com/api/getInTouch", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            name,
            email,
            message
        }),
    })
        .then((res) => {
            if (res.ok) {
                return res.json()
            } else {
                throw "Server error"
            }
        })
        .then((data) => {
            console.log(data)
            const notification = document.getElementById("email-response")
            notification.classList.add("appear")
            setTimeout(() => {
                notification.classList.remove("appear")
            }, 2000)
            document.getElementById("form-name").value = ""
            document.getElementById("form-email").value = ""
            document.getElementById("form-message").value = ""
        })
        .catch((err) => {
            console.log(err)
        })
})

///////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////DRONE EFFECT///////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////

// Scene
const scene2 = new THREE.Scene()
let drone = null
let rots = []
let trottles = []
trottles.push(document.querySelector("#trottle1"))
trottles.push(document.querySelector("#trottle2"))
trottles.push(document.querySelector("#trottle3"))
trottles.push(document.querySelector("#trottle4"))
console.log(trottles)
gltfLoader.load("./models/drone/drone.glb", (model) => {
    model.scene.scale.set(0.2, 0.2, 0.2)
    drone = model.scene
    drone.position.y = 15
    drone.rotation.y = -Math.PI * 0.5
    scene2.add(drone)
    console.log(drone)
    for (const i of drone.children) {
        if (i.name.startsWith("rot")) rots.push(i)
    }
    // gui2.add(drone.quaternion, "w").min(-1).max(1).step(0.25)
    // gui2.add(drone.quaternion, "x").min(-1).max(1).step(0.25)
    // gui2.add(drone.quaternion, "y").min(-1).max(1).step(0.25)
    // gui2.add(drone.quaternion, "z").min(-1).max(1).step(0.25)
    window.addEventListener("mousemove", (e) => {
        const x = e.pageX / window.innerWidth - 0.5
        let y = e.clientY / window.innerHeight - 0.5
        sphere.position.x = y * 70
        sphere.position.z = -x * 200
        if (transition != debugParameters.TRANS_MAX) {
            const calcy = Math.min(e.pageY, sizes.height)
            y = -1 * (calcy / sizes.height - 0.5)
            camera.position.x = 9 + x
            camera.position.y = 2 + y
        }
    })
})

// Temporary sphere
const sphere = new THREE.Mesh(
    new THREE.SphereGeometry(1, 32, 32),
    new THREE.MeshStandardMaterial({ roughness: 0.7 })
)
sphere.position.y = 15
scene2.add(sphere)

/*
 * Lights
 */
// Ambient light
const ambientLight = new THREE.AmbientLight("#ffffff", 0.5)
scene2.add(ambientLight)

// Directional light
const moonLight = new THREE.DirectionalLight("#ffffff", 0.5)
moonLight.position.set(4, 5, -2)
scene2.add(moonLight)

/**
 * Sizes
 */
const sizes2 = {
    width: window.innerWidth,
    height: canvas2.offsetHeight,
}

window.addEventListener("resize", () => {
    // Update sizes
    sizes2.width = window.innerWidth
    sizes2.height = canvas2.offsetHeight

    // Update camera
    camera2.aspect = sizes2.width / sizes2.height
    camera2.updateProjectionMatrix()

    // Update renderer
    renderer2.setSize(sizes2.width, sizes2.height)
    renderer2.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera2 = new THREE.PerspectiveCamera(75, sizes2.width / sizes2.height, 0.15, 200)
camera2.position.x = 35
camera2.position.y = 18
camera2.position.z = 0
camera2.rotation.set(-1.59, 0, 1.6)
scene2.add(camera2)
debugParameters.logCamera2 = () => {
    console.log(camera2.position)
    console.log(camera2.rotation)
    console.log(camera2.quaternion)
}
// gui.add(debugParameters, "logCamera2").name("logCamera2")
// gui.add(camera2.position, "x").name("x").min(35).max(70).step(0.1)
// gui.add(camera2.position, "y").name("y").min(20).max(30).step(0.1)

const tripPlate1 = new THREE.Mesh(
    new THREE.PlaneGeometry(10, 10),
    new THREE.MeshStandardMaterial({
        color: "#ff0000",
    })
)
tripPlate1.position.set(10, 0, 10)
tripPlate1.name = "0"
tripPlate1.rotation.x = -Math.PI / 2
// gui.add(tripPlate1.position, "y").name("trip height").min(0).max(10).step(0.1)
// gui.add(tripPlate1.rotation, "x").min(-Math.PI).max(Math.PI).step(0.1)
scene2.add(tripPlate1)

/**
 * Renderer
 */
const renderer2 = new THREE.WebGLRenderer({
    canvas: canvas2,
})
renderer2.setSize(sizes2.width, sizes2.height)
renderer2.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer2.setClearAlpha(0)
const hoverText = document.querySelectorAll(".hover-text")

// Floor
const floorWidth = 70
const floorLength = 200
const floorPointsCount = (floorWidth + 1) * (floorLength + 1)
const floorGeomentry = new THREE.BufferGeometry()
const floorPoints = new Float32Array(floorPointsCount * 3)
const floorMaterial = new THREE.PointsMaterial({
    color: "#ffffff",
    size: 0.4,
    sizeAttenuation: true,
    //map: planeTexture,
})
const floor = new THREE.Points(floorGeomentry, floorMaterial)
floor.rotation.x = -Math.PI * 0.5
floor.position.set(0, 0, 0)
scene2.add(floor)
const computeFloorPoints = (a, b) => {
    let count = 0
    for (let i = -35; i <= 35; i++) {
        for (let j = -100; j < 100; j++) {
            floorPoints[count++] = i
            floorPoints[count++] = j
            let temp = 0
            if (i - a < 12 && j - b < 12) {
                temp = 5 * Math.exp(-((i - a) * (i - a) + (j - b) * (j - b)) / 6)
            }
            floorPoints[count++] = temp
        }
    }
    floorGeomentry.setAttribute("position", new THREE.BufferAttribute(floorPoints, 3))
}
computeFloorPoints(0, 0)

let curr = null
const quart = new THREE.Quaternion(0, 0, 0, 1)
quart.normalize()
const droneControls = (deltaTime) => {
    const jump = 0.016 / 0.5
    const diffPosition = new THREE.Vector3()
    diffPosition.subVectors(sphere.position, drone.position)
    const displacement = Math.min(diffPosition.length(), 25)
    diffPosition.normalize()
    diffPosition.multiplyScalar(displacement)

    trottles[0].style.height = `${27 - diffPosition.z * 0.4 + diffPosition.x * 0.4}%`
    trottles[1].style.height = `${27 + diffPosition.z * 0.4 + diffPosition.x * 0.4}%`
    trottles[2].style.height = `${27 - diffPosition.z * 0.4 - diffPosition.x * 0.4}%`
    trottles[3].style.height = `${27 + diffPosition.z * 0.4 - diffPosition.x * 0.4}%`

    diffPosition.multiplyScalar(jump)
    quart.set(0.3 * diffPosition.z, 0, -0.5 * diffPosition.x, 1)
    quart.normalize()

    drone.quaternion.copy(quart)
    //drone.quaternion.normalize()
    drone.position.addVectors(drone.position, diffPosition)
    computeFloorPoints(drone.position.x, -drone.position.z)

    for (const i of rots) {
        i.rotation.y = i.rotation.y + jump * 10
    }

    // const rayDirection = drone.position.clone()
    // rayDirection.y = rayDirection.y - 20
    // rayDirection.normalize()
    // const ray = new THREE.Raycaster(drone.position, rayDirection)
    // const intersects = ray.intersectObjects([tripPlate1])

    // there will only be one element in intersects array
    // based on the assumption there is no overlaping trip planes
    // if (intersects.length > 0 && curr == null) {
    //     const index = parseInt(intersects[0].object.name)
    //     // const screenPosition = intersects[0].object.position.clone();
    //     // screenPosition.project(camera2);
    //     // console.log(screenPosition);
    //     hoverText[index].classList.add("appear")
    //     curr = hoverText[index]
    //     console.log("entered " + index)
    // }
    // if (curr != null && intersects.length === 0) {
    //     curr.classList.remove("appear")
    //     curr = null
    //     console.log("left")
    // }
}

window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = canvas.offsetHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    sizes2.width = window.innerWidth
    sizes2.height = canvas2.offsetHeight

    // Update camera
    camera2.aspect = sizes2.width / sizes2.height
    camera2.updateProjectionMatrix()

    // Update renderer
    renderer2.setSize(sizes2.width, sizes2.height)
    renderer2.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})
// const gui2 = new dat.GUI()
// gui2.add(camera2.position, "x").min(-15).max(15).step(0.1)
camera2.lookAt(0, 0, 0)

/**
 * Animate
 */
const clock = new THREE.Clock()
let lastTime = 0
let deltaTime = 0
const tick = (t) => {
    const elapsedTime = clock.getElapsedTime()
    deltaTime = elapsedTime - lastTime
    lastTime = elapsedTime

    // Update controls
    if (debug) controls.update(elapsedTime)

    TWEEN.update(t)

    if (drone) {
        drone.quaternion.normalize()
        droneControls(deltaTime)
    }
    // Render
    renderer.render(scene, camera)
    renderer2.render(scene2, camera2)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

const swiper = new Swiper('.swiper', {
    loop: true,
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    // autoplay: {
    //     delay: 3000,
    //     disableOnInteraction: true,
    // }
  });


tick()
